import React from 'react'
import { Container, Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HomeContent from '../components/layout/homepage'

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(post => !post.node.frontmatter.hidden && post.node.frontmatter.contentType === 'blog').slice(-2)
  return (
    <Layout>
      <Container>
        <HomeContent />
        {posts.map(({ node: post }) => (
          <Link to={post.frontmatter.path}>
          <Card style={{ marginBottom: 10 }} key={post.id}>
            <CardBody>
              <img className="featuredImage" src={post.frontmatter.thumbnail} alt={post.frontmatter.title}/>
              <CardTitle>{post.frontmatter.title}</CardTitle>
              <CardSubtitle style={{ marginBottom: 10 }}>{post.frontmatter.date}</CardSubtitle>
              <CardText>{post.excerpt}</CardText>
            </CardBody>
          </Card>
          </Link>
        ))}
        
      </Container>
    </Layout>
  )
}


export default IndexPage
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          frontmatter {
            title
            contentType
            thumbnail
            date(formatString: "DD-MM-YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`
