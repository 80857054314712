import React from 'react'
import { Container } from 'reactstrap'
import Link from 'gatsby-link'

import banner from "../../assets/images/homepage_banner_2019.svg"

const HomeContent = (props) => (
    <Container className="homeContent row">
        <div className="homeText col-lg-4">
            <h1><b>ΚΑΘΕ ΜΕΡΑ</b></h1>
            <p>Ο τρόπος ζωής με αυξημένα ωράρια και λίγες ώρες 
            ελεύθερου χρόνου, αναγκάζει πολλούς να στραφούν στην 
            κατανάλωση γρήγορων υγιεινών σνακ, τα οποία να πληρούν 
            τις προϋποθέσεις για προϊόντα υγιεινά, υψηλής ενέργειας 
            και χαμηλού γλυκαιμικού φορτίου.</p>
        </div>
        <Link to='/χειροποιητες-μπαρες-δημητριακων' className="ekato">
        <img src={banner} alt="homepage banner"></img></Link>
        <h2><b>Τα Νέα μας</b></h2>
    </Container>
)

export default HomeContent